<template>
  <div class="competition-banner-container">
    <v-row>
      <v-col cols="12" sm="12" md="6" class="pa-0">
        <div class="competition-banner-wm">
          <div class="competition-content-container">
            <h1>{{$t('cup.banner.wm.title')}} <span>{{$t('cup.banner.wm.titlePrimary')}}</span></h1>
            <h2>{{$t('cup.banner.wm.subtitle')}}</h2>
            <p>{{$t('cup.banner.wm.paragraph1')}}</p>
            <p>{{$t('cup.banner.wm.paragraph2')}}</p>
            <v-btn color="#33E39A" @click="goToWM()">{{$t('cup.banner.wm.toWM')}}</v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="pa-0">
        <div class="competition-banner-cups">
          <div class="competition-content-container">
            <h1>{{$t('cup.banner.cup.title')}} <span>{{$t('cup.banner.cup.titlePrimary')}}</span></h1>
            <h2>{{$t('cup.banner.cup.subtitle')}}</h2>
            <p>{{$t('cup.banner.cup.paragraph1')}}</p>
            <p>{{$t('cup.banner.cup.paragraph2')}}</p>
            <v-btn color="primary" @click="goToLandingpage()">{{$t('cup.banner.cup.toCups')}}</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'banner',
    data() {
      return {
      };
    },
    methods: {
      goToWM() {
        window.open('https://ebikewm.com', '_blank');
      },
      goToLandingpage() {
        this.$router.push({ name: 'home' });
      }
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";
  .competition-banner-container {
    .competition-banner-wm {
      background-image: url("https://ebikecup.com/images/cups/banner/EBCUP_Teaserbild_E-Bike_WM_01.jpg");
      h1 {
        span {
          color: #33E39A;
        }
      }
      h2 {
        color: #33E39A;
      }
    }
    .competition-banner-cups {
      background-image: url("https://ebikecup.com/images/cups/banner/EBCUP_Teaserbild_E-Bike_CUP_01.jpg");
      h1 {
        span {
          color: $primary;
        }
      }
    }
    .competition-banner-wm, .competition-banner-cups {
      background-position: center center;
      background-size: cover;
      min-height: 450px;
      position: relative;
      .competition-content-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: left;
        color: white;
        h1 {
          font-style: italic;
          font-weight: 600;
          @include MQ(L) {
            white-space: nowrap;
          }
          @include MQ(XL) {
            white-space: nowrap;
          }
        }
        h2 {
          font-weight: 500;
          margin-bottom: 25px;
          @include MQ(L) {
            white-space: nowrap;
          }
          @include MQ(XL) {
            white-space: nowrap;
          }
        }
        p {
          margin-bottom: 0;
        }
        .v-btn {
          margin-top: 35px;
          text-transform: none;
          border-radius: 10px;
          color: white;
        }
      }
    }
  }
</style>
