<template>
  <div class="cups-menu-container">
    <v-navigation-drawer temporary touchless right
                         fixed enable-resize-watcher disable-route-watcher
                         v-model="navigation.drawer" app>
      <v-list class="app-menu-wrapper">
        <p style="text-align: right; margin-right: 15px;">
          <a class="close-menu" @click.stop="navigation.drawer = !navigation.drawer">
            <v-icon large>{{icons.close}}</v-icon>
          </a>
        </p>
        <v-list-item>
          <v-list-item-content @click.stop="closeMenu('#about')">
            <v-list-item-title>
              {{ $t("cup.menu.about") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content @click.stop="closeMenu('#schedule')">
            <v-list-item-title>
              {{ $t("cup.menu.schedule") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!--<v-list-item>
          <v-list-item-content @click.stop="closeMenu('#route')">
            <v-list-item-title>
              {{ $t("cup.menu.route") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->
        <v-list-item>
          <v-list-item-content @click.stop="closeMenu('#register')">
            <v-list-item-title>
              {{ $t("cup.menu.register") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content @click.stop="closeMenu('#partner')">
            <v-list-item-title>
              {{ $t("cup.menu.partner") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content @click.stop="closeMenu('#faq')">
            <v-list-item-title>
              {{ $t("cup.menu.faq") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content @click.stop="closeMenu('#news')">
            <v-list-item-title>
              {{ $t("cup.menu.news") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="language-tile">
          <v-list-item-content>
            <v-list-item-title>
              <v-select solo :items="langs" v-model="lang"/>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar flat
               :class="[{'scrolled' : navigation.scrolled}, {'whitened': !navigation.scrolled}]">
      <v-toolbar-title>
        <span v-if="navigation.scrolled">
          <img alt="EBCUP" v-if="$route.name === 'entdeckerviertel'"
               src="https://ebikecup.com/images/cups/entdeckerviertel/EBCUP_Logo_Entdeckerviertel_Dunkel_01-01.svg">
          <img alt="EBCUP" v-else-if="$route.name === 'bozen'"
               src="https://ebikecup.com/images/cups/bozen/EBCUP_Logo_Bozen_Dunkel_01-01.svg">
          <img alt="EBCUP" v-else-if="$route.name === 'muerztal'"
               src="https://ebikecup.com/images/cups/muerztal/EBCUP_Logo_Muerztal_Dunkel_01-01.png">
          <img alt="EBCUP" v-else-if="$route.name === 'burgenland'"
               src="https://ebikecup.com/images/cups/burgenland/EBCUP_Logo_Burgenland_Dunkel_01-01.svg">
          <img alt="EBCUP" v-else-if="$route.name === 'hohesalve'"
               src="https://ebikecup.com/images/cups/hohesalve/EBCUP_Logo_Hohe_Salve_Dunkel_Licence_01-01.png">
        </span>
      </v-toolbar-title>
      <v-spacer v-if="navigation.scrolled || $vuetify.breakpoint.mdAndDown"/>
      <v-toolbar-items v-if="$vuetify.breakpoint.mdAndDown">
        <v-btn icon class="menu-icon"
               @click.stop="navigation.drawer = !navigation.drawer" :ripple="false">
          <v-icon large>{{icons.menu}}</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="justify-center" v-else>
        <v-btn outlined
               @click.stop="closeMenu('#about')">
          {{ $t("cup.menu.about") }}
        </v-btn>
        <v-btn outlined
               @click.stop="closeMenu('#schedule')">
          {{ $t("cup.menu.schedule") }}
        </v-btn>
        <!--<v-btn outlined
               @click.stop="closeMenu('#route')">
          {{ $t("cup.menu.route") }}
        </v-btn>-->
        <v-btn outlined
               @click.stop="closeMenu('#register')">
          {{ $t("cup.menu.register") }}
        </v-btn>
        <v-btn outlined
               @click.stop="closeMenu('#partner')">
          {{ $t("cup.menu.partner") }}
        </v-btn>
        <v-btn outlined
               @click.stop="closeMenu('#faq')">
          {{ $t("cup.menu.faq") }}
        </v-btn>
        <v-btn outlined
               @click.stop="closeMenu('#news')">
          {{ $t("cup.menu.news") }}
        </v-btn>
        <v-btn outlined
               @click.stop="closeMenu('#shop')">
          {{ $t("menu.shop") }}
        </v-btn>
        <v-select solo :items="langs" v-model="lang"/>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script>
  import { mdiClose, mdiMenu } from '@mdi/js'
  import i18next from 'i18next'

  export default {
    name: 'cups-menu',
    data () {
      return {
        icons: {
          menu: mdiMenu,
          close: mdiClose,
        },
        fab: false,
        navigation: {
          scrolled: false,
          drawer: false,
        },
        langs: ['DE', 'EN'],
        lang: 'DE',
        listener: undefined
      };
    },
    watch: {
      lang(language) {
        this.$store.dispatch('setLanguage', language);
        this.$vuetify.lang.current = this.$store.getters.getLanguage.toLowerCase();
        i18next.changeLanguage(this.$store.getters.getLanguage);
      },
      $route(to, from) {
        if (to.name !== from.name) {
          this.closeMenu();
        }
      },
    },
    beforeDestroy() {
      window.removeEventListener('keypress', this.scrollListener);
    },
    mounted() {
      window.addEventListener('scroll', this.scrollListener);
      this.$vuetify.lang.current = this.$store.getters.getLanguage.toLowerCase();
      i18next.changeLanguage(this.$store.getters.getLanguage);
      this.lang = this.$store.getters.getLanguage;
    },
    methods: {
      scrollListener() {
        if (!this.isIE11()) {
          this.navigation.scrolled = window.scrollY > 50;
        } else {
          this.navigation.scrolled = window.pageYOffset > 50;
        }
      },
      closeMenu(selector) {
        this.navigation.drawer = false;
        if (selector !== undefined && selector !== null) {
          this.globalEvents.$emit('goToElementCups', selector);
        }
      },
      isIE11() {
        return window.navigator.userAgent.indexOf('Trident/7.0') > -1;
      },
      getLanguage() {
        return this.$store.getters.getLanguage;
      },
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";

  .cups-menu-container {
    .v-toolbar {
      background-color: transparent;
      box-shadow: unset;
      position: fixed;
      width: 100%;
      z-index: 100;

      /* IE11 */
      @media all and (-ms-high-contrast:none)
      {
        box-shadow: none;
      }

      .v-toolbar__content {
        .v-toolbar__title {
          img {
            width: auto;
            //height: 100px;
            //top: 25px;
            //left: 30px;
            height: 70px;
            top: 5px;
            left: 20px;
            position: absolute;
            cursor: pointer;
            -webkit-transition: width 0.2s ease, height 0.2s ease;
          }
        }
        .v-toolbar__items {
          @include MQ(XL) {
            width: 100%;
          }
          @include MQ(L) {
            width: 100%;
          }
          .v-btn {
            border: none;
            text-transform: none;
            color: white;
            text-indent: 0;
            .v-btn__content {
              font-family: $default-font;
              font-weight: 600;
            }
          }
          .v-input {
            max-width: 100px;
            .v-input__control {
              height: 100%;
              max-width: 100px;
              .v-input__slot {
                max-width: 85px;
                background-color: transparent !important;
                box-shadow: unset !important;
                height: 100%;
                margin-bottom: 0;
                /* IE11 */
                @media all and (-ms-high-contrast:none)
                {
                  box-shadow: none !important;
                }
                .v-select__selections {
                  color: white;
                  font-weight: 500;
                  /* IE11 */
                  @media all and (-ms-high-contrast:none)
                  {
                    height: 31px;
                  }
                  input {
                    width: 5px;
                  }
                }
                .v-input__append-inner {
                  .v-input__icon {
                    svg {
                      color: white !important;
                    }
                  }
                }
              }
              .v-text-field__details {
                display: none;
              }
            }
          }
        }
      }

      .menu-icon {
        .v-icon {
          color: white;
        }
      }

      &.whitened {
        .v-toolbar__content {
          .v-toolbar__items {
            .v-btn {
              color: white;
            }
            .v-input {
              .v-input__control {
                .v-input__slot {
                  .v-select__selections {
                    color: white;
                  }
                  .v-input__append-inner {
                    .v-input__icon {
                      svg {
                        color: white !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.scrolled {
        width: unset;
        background-color: white;
        z-index: 100;
        box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2),
        0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
        .v-toolbar__items {
          width: unset;
        }
        .v-toolbar__content {
          width: unset;
          img {
            height: 55px;
            top: 5px;
            left: 10px;
            -webkit-transition: width 0.2s ease, height 0.2s ease;
            @include MQ(XS) {
              top: 0;
            }
            @include MQ(S) {
              top: 0;
            }
          }
          .v-toolbar__items {
            .v-btn {
              color: $primary-text;
            }
            .v-input {
              .v-input__control {
                .v-input__slot {
                  .v-select__selections {
                    color: $primary-text;
                  }
                  .v-input__append-inner {
                    .v-input__icon {
                      svg {
                        color: $primary-text !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .menu-icon {
          .v-icon {
            color: $primary-text;
          }
        }
      }
    }

    .v-list {
      .v-list-item {
        .v-list-item__content {
          text-align: left;
          color: $primary-text;
          font-family: $default-font;
        }
      }
    }

    .v-navigation-drawer {
      color: $primary-text;
      z-index: 1001;
      text-align: left;
      font-family: $default-font;

      .close-menu {
        svg {
          fill: $primary-text;
        }
      }

      .v-input {
        .v-input__control {
          .v-input__slot {
            padding: 0;
            box-shadow: none;
            min-height: 30px;
          }
          .v-text-field__details {
            display: none;
          }
        }
      }
    }
  }
</style>
