<template>
  <div class="register-container" id="register">
    <div class="register-cup-logo">
      <v-img :src="cupLogo"/>
    </div>
    <h1>{{$t('cup.register.title')}}</h1>
    <div class="register-button-container">
      <div class="register-button" @click="goToRegister()">
        <div>{{$t('cup.register.registerNow')}}</div>
      </div>
    </div>
    <img class="register-triangle" src="https://ebikecup.com/images/cups/EBCUP_Backgroundimage_Dreiecke_01.jpg" alt=""/>
    <img class="transparent-separator" src="https://ebikecup.com/images/cups/EBCUP_Trenner_Weiss_01.svg" alt=""/>
  </div>
</template>

<script>
  export default {
    name: 'register',
    props: {
      cupLogo: undefined,
      competition: undefined
    },
    methods: {
      goToRegister() {
        this.$router.push({ path: 'register', query: { competition: this.competition } })
      },
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";
  .register-container {
    position: relative;
    overflow: hidden;
    @include MQ(XXL) {
      min-height: 740px;
    }
    .register-cup-logo {
      z-index: 1;
      position: relative;
      padding-top: 40px;
      padding-bottom: 50px;
      .v-image {
        max-height: 150px;
        @include MQ(XS) {
          max-height: 100px;
        }
        .v-image__image--cover {
          background-size: contain;
        }
      }
    }
    h1 {
      z-index: 1;
      position: relative;
    }
    .register-button-container {
      z-index: 1;
      position: relative;
      padding-top: 50px;
      padding-bottom: 150px;
      @include MQ(XS) {
        padding-bottom: 80px;
      }
      .register-button {
        background: url("https://ebikecup.com/images/cups/EBCUP_Button_Pinselhintergrund_Grau_01.svg") center no-repeat;
        background-size: cover;
        font-size: 30px;
        display: inline-block;
        height: 70px;
        width: 355px;
        position: relative;
        cursor: pointer;
        @include MQ(XS) {
          height: 50px;
          width: 305px;
          font-size: 25px;
        }
        div {
          position: absolute;
          top: 45%;
          left: -25px;
          transform: translateY(-50%);
          width: 100%;
          color: $primary-text;
          font-style: italic;
          font-weight: 600;
          @include MQ(XS) {
            top: 48%;
            left: -18px;
          }
        }
      }
    }
    .register-triangle {
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: 0;
      left: 0;
      right: 0;
      display: inline-block;
    }
    .transparent-separator {
      position: absolute;
      bottom: -16px;
      left: 0;
      right: 0;
      display: inline-block;
      z-index: 1;
      width: 100%;
      background: transparent;
      @include MQ(XS) {
        bottom: -2px;
      }
    }
  }
</style>
